export const profiles = {
	honza: {
		name: 'Honza Borysek',
		imagePath: '/images/honzaborysek.png',
		twitterLink: 'https://twitter.com/metahonza',
		linkedinLink: 'https://www.linkedin.com/in/honzaborysek/',
	},
	jan: {
		name: 'Jan Hostinsky',
		imagePath: '/images/janhostinsky.png',
		twitterLink: 'https://twitter.com/HostaJohnny',
		linkedinLink: 'https://www.linkedin.com/in/jan-hostinsky-%F0%9F%8E%AF-7b1291157/',
	},
}
