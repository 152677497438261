import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import { profiles } from '../../helpers/profiles'
import SvgLinkedin from '../../svg/icons/Linkedin'
import SvgTwitter from '../../svg/icons/Twitter'
import style from './ProfileCards.module.sass'

export const ProfileCards: FunctionComponent = () => {
	return (
		<div className={style.root}>
			<div className={style.profile}>
				<h6 className={style.title}>{profiles.jan.name}</h6>
				<Link href={profiles.jan.twitterLink}>
					<a className={style.socialWrapper}>
						<div className={style.profileImageWrapper}>
							<Image
								src={profiles.jan.imagePath}
								alt={profiles.jan.name}
								layout="fill"
								objectFit="contain"
							/>
						</div>
					</a>
				</Link>
				<div className={style.socials}>
					<Link href={profiles.jan.twitterLink}>
						<a className={clsx(style.socialWrapper, style.socialWrapperTwitter)}>
							<SvgTwitter />
						</a>
					</Link>
					<Link href={profiles.jan.linkedinLink}>
						<a className={style.socialWrapper}>
							<SvgLinkedin />
						</a>
					</Link>
				</div>
			</div>
			<div className={style.profile}>
				<h6 className={style.title}>{profiles.honza.name}</h6>
				<Link href={profiles.honza.twitterLink}>
					<a className={style.socialWrapper}>
						<div className={style.profileImageWrapper}>
							<Image
								src={profiles.honza.imagePath}
								alt={profiles.honza.name}
								layout="fill"
								objectFit="contain"
							/>
						</div>
					</a>
				</Link>
				<div className={style.socials}>
					<Link href={profiles.honza.twitterLink}>
						<a className={clsx(style.socialWrapper, style.socialWrapperTwitter)}>
							<SvgTwitter />
						</a>
					</Link>
					<Link href={profiles.honza.linkedinLink}>
						<a className={style.socialWrapper}>
							<SvgLinkedin />
						</a>
					</Link>
				</div>
			</div>
		</div>
	)
}
