import clsx from 'clsx'
import { FunctionComponent, ReactNode, useState } from 'react'
import SvgChevronDown from '../../svg/icons/ChevronDown'
import { Collapsible } from '../shared/Collapsible'
import style from './ToggleButton.module.sass'

type ToggleButtonProps = {
	title: string
	content: ReactNode
}

export const ToggleButton: FunctionComponent<ToggleButtonProps> = ({ title, content }) => {
	const [isCollapsibleOpen, setIsCollapsibleOpen] = useState(false)

	return (
		<>
			<button
				className={style.button}
				type="button"
				onClick={() => {
					setIsCollapsibleOpen(!isCollapsibleOpen)
				}}>
				{title}
				<span className={clsx(style.buttonIcon, isCollapsibleOpen && style.buttonIconRotated)}>
					<SvgChevronDown />
				</span>
			</button>
			<Collapsible open={isCollapsibleOpen}>
				<div className={style.content}>{content}</div>
			</Collapsible>
		</>
	)
}
