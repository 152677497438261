import clsx from 'clsx'
import { default as React } from 'react'
import style from './Swiper.module.sass'

export type SwiperProps = {
	content: JSX.Element
}

export function Swiper(props: SwiperProps) {
	return (
		<>
			<div className={clsx(style.container)}>
				<div className={style.Items}>
					<div className={style.ItemsCarousel}>
						<div className={style.Catch} />
						{props.content}
					</div>
				</div>
			</div>
		</>
	)
}
