import Image from 'next/image'
import type { FunctionComponent } from 'react'
import style from './Card.module.sass'

export type CardProps = {
	title: string
	imagePath: string
}

export const Card: FunctionComponent<CardProps> = ({ title, imagePath }) => {
	return (
		<div className={style.root}>
			<h6 className={style.title}>{title}</h6>
			<div className={style.backgroundImageWrapper}>
				<Image src={imagePath} alt={title} layout="fill" objectFit="cover" />
			</div>
		</div>
	)
}
