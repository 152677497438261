export const servicesList = [
	{
		title: 'Communications strategy',
		content: (
			<>
				At the beginning of each collaboration, we define a communications strategy. Such a strategy
				helps to ensure a smooth implementation, while at the same time allowing for evaluation of
				individual results towards the fulfilment of the communication and PR objectives.
				<br />
				<br />
				Through workshops, we get to know your company or project and then build the communication
				pillars together, as well as define the goals we wish to achieve and their associated
				resources.
			</>
		),
		id: 1,
	},
	{
		title: 'Public relations',
		content:
			'Every brand has its future. Let us help you strategically communicate yours in the best way to reach your audience and meet your goals. Our approach is customized for each individual client because there is no one-size-fits-all approach for every product or service. Our ideas are fresh and new. And to ensure effective results, we always develop a unique strategy.',
		id: 2,
	},
	{
		title: 'Media outcomes',
		content:
			'Our responsibility as a PR agency is to create constructive publicity for brands and build sufficient awareness and trust with the media. We discover relevant topics for our clients with strong, connected themes that resonate strongly and create high visibility. We help open doors for tech startups, SMEs and large corporations to the media world so that their work will be noticed and appreciated by the general public.',
		id: 3,
	},
	{
		title: 'Community building',
		content: (
			<>
				In today&apos;s highly-digital environment, user groups and communities are the biggest
				movers and shakers, not corporate giants. Being part of such a community means actively
				deciding on its direction, plans and goals.
				<br />
				<br />
				As a brand or project, it is important to start building your own community as early as
				possible. Whether it&apos;s through Discord, Telegram or Twitter, we&apos;ll work with you
				to set up everything you need and then use airdrops, giveaways and other activation methods
				to help grow the community. Let your fans build your project with you, because they will
				oftentimes share constructive and helpful insights voluntarily.
			</>
		),
		id: 4,
	},
	{
		title: 'Reputation management',
		content: (
			<>
				Even one inappropriately worded comment on social media can spark a major five alarm fire
				and leave your company&apos;s online reputation full of negative feedback. Your reputation
				matters, both online and offline. It fundamentally affects all stakeholders: customers,
				employees, investors and even industry peers.
				<br />
				<br />
				Online and offline reputation management is a combination of techniques and strategies that
				positively impact the perception of your company. With the right reputation management
				strategy, you can build credibility and trust within your community as well as protect
				yourself from potentially damaging situations.
			</>
		),
		id: 5,
	},
	{
		title: 'Partnerships and collabs',
		content:
			'We believe that synergies between companies are the way to grow, which is why we are a mediator in finding quality partners for your business. Nowadays, especially with internet and crypto projects, partnerships and collaborations are an effective way to garner credibility, and thus new customers, contracts and other business opportunities.',
		id: 6,
	},
	{
		title: 'Personal branding',
		content: (
			<>
				Public relations is an exercise in credibility and trust, which must be established with
				both journalists and readers. Therefore, it is essential to be clear about what you stand
				for, what your views are and why you are focusing on a given topic in the first place.
				<br />
				<br />
				Once a person has a long-term and established recognition, it is easy to build on previous
				activities and create an increasingly strong personal brand. Every personality, celebrity or
				influencer uses personal branding as a key to growth.
			</>
		),
		id: 7,
	},
]
