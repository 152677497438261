import React, { FunctionComponent } from 'react'
import { Container } from '../shared/Container'
import styles from './Footer.module.sass'

export const Footer: FunctionComponent = () => {
	const actualYear = new Date().getFullYear()

	return (
		<footer className={styles.footer}>
			<Container size="wide">
				PUNCHLINE / All Right Reserved {actualYear} ~ spacepace&nbsp;s.r.o., IČ:&nbsp;6750753
			</Container>
		</footer>
	)
}
