import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import React, { FunctionComponent, useState } from 'react'
import { menuItems } from '../../helpers/menuItems'
import style from './Header.module.sass'

export const Header: FunctionComponent = () => {
	const [isOpen, setIsOpen] = useState(false)

	const scrollIt = (anchor: string) => {
		if (typeof document !== 'undefined') {
			const element = document.querySelector(anchor)
			return element?.scrollIntoView({
				behavior: 'smooth',
			})
		}
	}
	const onClick = (anchor: string) => {
		scrollIt(anchor)
		setIsOpen(!isOpen)
	}

	return (
		<header className={style.root}>
			<h1 className={style.logoWrapper}>
				<Link href="/">
					<a aria-label="Punchline!" className={style.logo}>
						<Image src="/images/logo.png" alt="logo" layout="fill" objectFit="contain" />
					</a>
				</Link>
			</h1>
			<nav className={style.menu}>
				<ul className={style.menuItems}>
					{menuItems.map((item) => (
						<div className={style.menuItemWrapper} key={item.title}>
							<button
								onClick={() => {
									scrollIt(item.anchor)
								}}>
								<span className={style.menuItem}>
									<li
										className={clsx(item.isActive && style.menuItemBodyActive, style.menuItemBody)}>
										{item.title}
									</li>
								</span>
							</button>
						</div>
					))}
				</ul>
			</nav>
			<button
				className={style.buttonDesktop}
				onClick={() => {
					scrollIt('#getInTouch')
				}}>
				<span className={style.button}>Get in touch</span>
			</button>
			<div className={style.hamburgerWrapper}>
				<button
					className={clsx(isOpen && style.hamburgerIsOpen, style.hamburger)}
					type="button"
					onClick={() => setIsOpen(!isOpen)}
					aria-label="Menu button"
					data-track-click={JSON.stringify({
						event: 'clickTo_navigation',
						menu: {
							type: 'menu',
						},
						link: { text: isOpen ? 'open' : 'close' }, // isOpen value changes before this event is triggered
					})}>
					<span className={style.textMenu}>Menu</span>
				</button>
			</div>
			{isOpen && (
				<div className={style.navigationMenu}>
					<ul className={style.menuItemsMobile}>
						{menuItems.map((item) => (
							<button
								onClick={() => {
									onClick(item.anchor)
								}}
								className={style.menuItemWrapperMobile}
								key={item.title}>
								<span className={style.menuItemMobile}>
									<li
										className={clsx(
											item.isActive && style.menuItemBodyMobileActive,
											style.menuItemBodyMobile
										)}>
										{item.title}
									</li>
								</span>
							</button>
						))}
						<button
							className={style.buttonMobile}
							onClick={() => {
								setIsOpen(!isOpen)
								scrollIt('#getInTouch')
							}}>
							<span>Get in touch</span>
						</button>
					</ul>
				</div>
			)}
		</header>
	)
}
