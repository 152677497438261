import Image from 'next/image'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import { contact } from '../../helpers/links'
import { HouseGroup } from '../shared/HouseGroup'
import { TitleHighlighted } from '../shared/TitleHighlighted'
import style from './ContactSection.module.sass'

export const ContactSection: FunctionComponent = () => {
	return (
		<div className={style.root} id="getInTouch">
			<TitleHighlighted
				title={
					<>
						Let us introduce you
						<br />
						to your future community!
					</>
				}
				backgroundTitle="Get in touch"
				isTwoLines
			/>
			<div className={style.contact}>
				<Link href={contact.punchlineMail}>
					<a className={style.link}>future@punchline.cz</a>
				</Link>
				<Link href={contact.punchlinePhone}>
					<a className={style.link}>+420 608 280 640</a>
				</Link>
				<Link href={contact.punchlineFacebook}>
					<a className={style.link}>LinkedIn</a>
				</Link>
				<Link href={contact.punchlineInstagram}>
					<a className={style.link}>Instagram</a>
				</Link>
			</div>
			<div className={style.imageWrapper}>
				<Image src="/images/hand-4.png" alt="Let's go!" layout="fill" objectFit="contain" />
			</div>
			<div className={style.header}>
				<h5 className={style.title}>Headquarters</h5>
				<h6 className={style.subtitle}>
					Worldwide Web3&nbsp;
					<br />
					#7854
				</h6>
			</div>
			<div className={style.backgroundWrapper}>
				<HouseGroup />
			</div>
		</div>
	)
}
