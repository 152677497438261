import type { FunctionComponent } from 'react'
import { Footer } from '../layout/Footer'
import { Header } from '../layout/Header'
import style from './Layout.module.sass'

export const Layout: FunctionComponent = ({ children }) => {
	return (
		<>
			<Header />
			<div className={style.main}>{children}</div>
			<Footer />
		</>
	)
}
