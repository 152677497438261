import clsx from 'clsx'
import Image from 'next/image'
import type { FunctionComponent } from 'react'
import { houseImages, mobileHouseImages } from '../../helpers/houses'
import style from './HouseGroup.module.sass'

export const HouseGroup: FunctionComponent = () => {
	return (
		<div className={style.root}>
			<div className={clsx(style.wrapperDesktop, style.wrapper)}>
				{houseImages.map((house) => (
					<div className={style.imageWrapper} key={house.imagePath}>
						<Image
							src={house.imagePath}
							alt={house.alternativeText}
							width={house.width}
							height={house.height}
						/>
					</div>
				))}
			</div>
			<div className={clsx(style.wrapper, style.wrapperMobile)}>
				{mobileHouseImages.map((house) => (
					<div className={style.imageWrapper} key={house.imagePath}>
						<Image
							src={house.imagePath}
							alt={house.alternativeText}
							width={house.width}
							height={house.height}
						/>
					</div>
				))}
			</div>
		</div>
	)
}
