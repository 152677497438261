/* eslint-disable @next/next/no-page-custom-font */
import clsx from 'clsx'
import Head from 'next/head'
import Image from 'next/image'
import { CardSection } from '../app/components/sections/CardSection'
import { ContactSection } from '../app/components/sections/ContactSection'
import { FoundersSection } from '../app/components/sections/FoundersSection'
import { ReferencesSection } from '../app/components/sections/ReferencesSection'
import { ServicesSection } from '../app/components/sections/ServicesSection'
import { Hero } from '../app/components/shared/Hero'
import { Layout } from '../app/components/shared/Layout'
import { LogoRow } from '../app/components/shared/LogoRow'
import { logosContent } from '../app/helpers/logos'
import style from '../app/styles/Home.module.sass'

export default function Home() {
	return (
		<Layout>
			<Head>
				<title>Punchline | Future-focused PR agency</title>
				<meta
					name="description"
					content="Professionals dedicated to communicating about innovation since 2018. We write the future - web3, crypto, blockchain, NFT, metaverse, AR/VR, future of food and more. Let us introduce you to your future community!"
				/>
				<meta name="image" content="https://www.punchline.cz/images/thumbnail.png" />
				<link rel="icon" href="/favicon.ico" />
				<link rel="image_src" href="https://www.punchline.cz/images/thumbnail.png" />
				<meta property="og:title" content="Punchline | Future-focused PR agency" />
				<meta
					property="og:description"
					content="Professionals dedicated to communicating about innovation since 2018. We write the future - web3, crypto, blockchain, NFT, metaverse, AR/VR, future of food and more. Let us introduce you to your future community!"
				/>
				<meta property="og:image" content="https://www.punchline.cz/images/thumbnail.png" />
				<meta property="og:image:width" content="2878" />
				<meta property="og:image:height" content="1520" />
				<meta property="og:url" content="https://www.punchline.cz" />
			</Head>

			<main className={style.main}>
				<div id="home" className={style.section}>
					<Hero />
				</div>
				<div id="weBelieveIn" className={style.section}>
					<CardSection />
				</div>
				<div id="services" className={style.section}>
					<ServicesSection />
				</div>
				<div id="references" className={style.section}>
					<ReferencesSection />
				</div>
				<div className={clsx(style.section, style.sectionLogos)}>
					<LogoRow logos={logosContent} />
					<div className={style.backgroundImageWrapper}>
						<Image
							src="/images/hands-together.png"
							alt="It's a deal!"
							layout="fill"
							objectFit="contain"
						/>
					</div>
				</div>
				<div id="founders" className={style.section}>
					<FoundersSection />
				</div>
				<div id="getInTouch" className={style.section}>
					<ContactSection />
				</div>
			</main>
		</Layout>
	)
}
