import Image from 'next/image'
import type { FunctionComponent } from 'react'
import { servicesList } from '../../helpers/services'
import { ServicesMobile } from '../shared/ServicesMobile'
import { Tabs } from '../shared/Tabs'
import { TitleHighlighted } from '../shared/TitleHighlighted'
import style from './ServicesSection.module.sass'

export const ServicesSection: FunctionComponent = () => {
	return (
		<div className={style.root}>
			<TitleHighlighted title="Our knowhow" backgroundTitle="Services" />
			<div className={style.contentDesktop}>
				<Tabs tabItems={servicesList} />
			</div>
			<div className={style.contentMobile}>
				<ServicesMobile services={servicesList} />
			</div>
			<div className={style.imageHandSmallWrapper}>
				<Image src="/images/hand-1.png" alt="Top notch!" width={220} height={300} />
			</div>
			<div className={style.imageHandBigWrapper}>
				<Image src="/images/hand-2.png" alt="Comprende?" layout="fill" objectFit="contain" />
			</div>
		</div>
	)
}
