import type { FunctionComponent } from 'react'
import { cards } from '../../helpers/cards'
import { CardGroup } from '../shared/CardGroup'
import { TitleHighlighted } from '../shared/TitleHighlighted'
import style from './CardSection.module.sass'

export const CardSection: FunctionComponent = () => {
	return (
		<div className={style.root}>
			<TitleHighlighted title="We believe in" backgroundTitle="Our expertise" />
			<CardGroup cards={cards} />
		</div>
	)
}
