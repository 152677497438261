export const houseImages = [
	{ alternativeText: 'It is a house!', imagePath: '/images/house-1.png', width: 179, height: 235 },
	{ alternativeText: 'It is a house!', imagePath: '/images/house-2.png', width: 146, height: 383 },
	{ alternativeText: 'It is a house!', imagePath: '/images/house-3.png', width: 146, height: 383 },
	{ alternativeText: 'It is a house!', imagePath: '/images/house-4.png', width: 152, height: 279 },
	{ alternativeText: 'It is a house!', imagePath: '/images/house-5.png', width: 179, height: 235 },
	{ alternativeText: 'It is a house!', imagePath: '/images/house-6.png', width: 146, height: 383 },
	{
		alternativeText: 'Punchline office!',
		imagePath: '/images/house-7-office.png',
		width: 182,
		height: 336,
	},
	{ alternativeText: 'It is a house!', imagePath: '/images/house-8.png', width: 152, height: 279 },
	{ alternativeText: 'It is a house!', imagePath: '/images/house-9.png', width: 179, height: 235 },
	{ alternativeText: 'It is a house!', imagePath: '/images/house-10.png', width: 179, height: 235 },
	{ alternativeText: 'It is a house!', imagePath: '/images/house-11.png', width: 146, height: 383 },
	{ alternativeText: 'It is a house!', imagePath: '/images/house-12.png', width: 152, height: 279 },
	{ alternativeText: 'It is a house!', imagePath: '/images/house-13.png', width: 179, height: 235 },
]

export const mobileHouseImages = [
	{ alternativeText: 'It is a house!', imagePath: '/images/house-5.png', width: 179, height: 235 },
	{ alternativeText: 'It is a house!', imagePath: '/images/house-6.png', width: 146, height: 383 },
	{
		alternativeText: 'Punchline office!',
		imagePath: '/images/house-7-office.png',
		width: 182,
		height: 336,
	},
	{ alternativeText: 'It is a house!', imagePath: '/images/house-8.png', width: 152, height: 279 },
	{ alternativeText: 'It is a house!', imagePath: '/images/house-9.png', width: 179, height: 235 },
]
