/* eslint-disable jsx-a11y/anchor-has-content */
import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import { FunctionComponent, useMemo, useState } from 'react'
import style from './ReferenceCard.module.sass'

type ReferenceCardProps = {
	imagePath: string
	logoPath: string
	title: string
	logoUrl?: string
	content: string
	contentElement: JSX.Element
}

export const ReferenceCard: FunctionComponent<ReferenceCardProps> = ({
	imagePath,
	title,
	content,
	logoPath,
	logoUrl,
	contentElement,
}) => {
	const [isMobileContentFull, setIsMobileContentFull] = useState(false)

	const longContent = content.length > 240
	const lastCharactedVisible = 210

	const ellipsedContent = useMemo(() => {
		if (longContent) {
			const shortenedText = content.slice(0, lastCharactedVisible)
			const lastSpacePosition = shortenedText.lastIndexOf(' ')
			const output = `${shortenedText.slice(0, lastSpacePosition)} …`
			if (output.slice(-3) === '. …') {
				return shortenedText.slice(0, lastSpacePosition)
			}

			return output
		}
		return content
	}, [longContent, content])

	return (
		<div className={style.root}>
			<div className={style.backgroundImageWrapper}>
				<Image
					src={imagePath}
					alt={`${title} - reference, is it?`}
					layout="fill"
					objectFit="cover"
				/>
			</div>
			<div className={style.content}>
				<h4 className={style.title}>{title}</h4>
				<div className={clsx(style.logoWrapper, style[`logoWrapper_${title}`])}>
					<Image src={logoPath} alt={title} layout="fill" objectFit="contain" />
					{logoUrl && (
						<Link href={logoUrl}>
							<a className={style.link} />
						</Link>
					)}
				</div>
				<div className={style.body}>
					<p className={style.text}>{contentElement}</p>
				</div>
				<div className={style.bodyMobile}>
					<p className={style.text}>
						{isMobileContentFull && longContent ? (
							content
						) : (
							<span className={style.ellipsedContent}>{ellipsedContent}</span>
						)}
						{!isMobileContentFull && longContent && (
							<button className={style.button} onClick={() => setIsMobileContentFull(true)}>
								Read more
							</button>
						)}
					</p>
				</div>
			</div>
		</div>
	)
}
