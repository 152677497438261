import clsx from 'clsx'
import Image from 'next/image'
import type { FunctionComponent } from 'react'
import { logosHero } from '../../helpers/logos'
import style from './Hero.module.sass'
import { LogoRow } from './LogoRow'

const changingText = ['metaverse', 'web3', 'crypto', 'blockchain', 'NFT', 'AR/VR', 'future of food']

export const Hero: FunctionComponent = () => {
	if (typeof window !== 'undefined' && typeof document !== 'undefined') {
		const text = document.querySelector('#text') as HTMLElement
		let i = 1

		window.setInterval(() => {
			text.textContent = changingText[i]
			i = ++i % changingText.length
		}, 2600)
	}

	return (
		<div className={style.root}>
			<div className={style.header}>
				<h2 className={style.title}>
					We write <span className={style.titleLarge}>the future</span>
				</h2>
				<div className={style.subtitleWrapper}>
					<p className={style.subtitle}>Future-focused PR agency talking about</p>
					<span className={clsx(style.goldColor, style.changingText)} id="text">
						metaverse
					</span>
				</div>
			</div>
			<div className={style.logosWrapper}>
				<LogoRow logos={logosHero} />
			</div>
			<div className={style.imageGroup}>
				<div className={style.imageHandWrapper}>
					<Image src="/images/hand-3.png" alt="Hey there!" width={310} height={420} />
				</div>
				<div className={style.imageHandWarriorWrapper}>
					<Image src="/images/hand-warrior-1.png" alt="Let's go!" width={380} height={330} />
				</div>
			</div>
		</div>
	)
}
