import Image from 'next/image'
import type { FunctionComponent } from 'react'
import { ProfileCards } from '../shared/ProfileCards'
import { TitleHighlighted } from '../shared/TitleHighlighted'
import style from './FoundersSection.module.sass'

export const FoundersSection: FunctionComponent = () => {
	return (
		<div className={style.root}>
			<div className={style.profiles}>
				<ProfileCards />
			</div>
			<div className={style.content}>
				<TitleHighlighted
					title="We are here for you"
					backgroundTitle={
						<>
							Foun
							<br />
							ders
						</>
					}
					isAlignedToLeft
				/>
				<p className={style.body}>
					Hey! It&apos;s Jan and Honza! Professionals dedicated to communicating about innovation
					since 2018. Our projects have helped to create the foundations for entirely new areas of
					business. And some of our clients — carsharing, NFT, cryptocurrencies and virtual reality
					— are now the leaders of future industries.
					<br /> <br />
					We are backed by a team of experienced strategists, creatives and writers. We treat each
					project as our own and always carefully construct a team that fits the project at hand. So
					that our mindsets are on the same page.
				</p>
			</div>
			<div className={style.backgroundImageWrapper}>
				<Image src="/images/hand-warrior-2.png" alt="Try us!" layout="fill" objectFit="contain" />
			</div>
		</div>
	)
}
