export const cards = [
	{ title: 'Digitalization', imagePath: '/images/digitalization.jpg' },
	{ title: 'Blockchain', imagePath: '/images/Blockchain.jpg' },
	{ title: 'Web3', imagePath: '/images/Web3.jpg' },
	{ title: 'NFT', imagePath: '/images/NFT.jpg' },
	{ title: 'Cryptocurrencies', imagePath: '/images/Cryptocurrencies.jpg' },
	{ title: 'DeFi', imagePath: '/images/deFi.jpg' },
	{ title: 'eHealth', imagePath: '/images/eHealth.jpg' },
	{ title: 'Metaverse', imagePath: '/images/metaverse.jpg' },
	{ title: 'Future of foods', imagePath: '/images/futureOfFoods.jpg' },
]
