import React, { FunctionComponent, ReactChild, useState } from 'react'
import { TabButtons } from './TabButtons'
import style from './Tabs.module.sass'

type TabsProps = {
	tabItems: { content: ReactChild; title: string; id: number }[]
}

export const Tabs: FunctionComponent<TabsProps> = ({ tabItems }) => {
	const [activeTab, setActiveTab] = useState(tabItems[0].title)

	return (
		<div className={style.root}>
			<div className={style.buttonGroup}>
				{tabItems.map((item) => (
					<TabButtons
						key={item.id}
						activeLabel={activeTab}
						label={item.title}
						changeTab={() => setActiveTab(item.title)}
					/>
				))}
			</div>
			<div className={style.content}>
				{tabItems.map((item) => (
					<span key={item.id}>
						{activeTab === item.title && <div className={style.tabContent}>{item.content}</div>}
					</span>
				))}
			</div>
		</div>
	)
}
