import clsx from 'clsx'
import React, { FunctionComponent } from 'react'
import style from './TabButtons.module.sass'

type TabButtonsProps = {
	changeTab: (button: string) => void
	label: string
	activeLabel: string
}

export const TabButtons: FunctionComponent<TabButtonsProps> = ({
	changeTab,
	label,
	activeLabel,
}) => {
	return (
		<button
			className={clsx(style.button, label === activeLabel && style.buttonActive)}
			onMouseEnter={() => changeTab(label)}>
			{label}
		</button>
	)
}
