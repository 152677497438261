/* eslint-disable jsx-a11y/anchor-has-content */
import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import style from './LogoRow.module.sass'

export type LogoRowProps = {
	logos: { title: string; imagePath: string; url?: string }[]
}

export const LogoRow: FunctionComponent<LogoRowProps> = ({ logos }) => {
	return (
		<div className={style.root}>
			{logos.map((logo) => (
				<div
					className={clsx(style.logoWrapper, logo.url && style.logoWrapperHovered)}
					key={logo.title}>
					<Image alt={logo.title} src={logo.imagePath} layout="fill" objectFit="contain" />
					{logo.url && (
						<Link href={logo.url}>
							<a className={style.link} />
						</Link>
					)}
				</div>
			))}
		</div>
	)
}
