import type { FunctionComponent } from 'react'
import { references } from '../../helpers/references'
import { ReferenceCard } from '../shared/ReferenceCard'
import { Swiper } from '../shared/Swiper'
import { TitleHighlighted } from '../shared/TitleHighlighted'
import style from './ReferencesSection.module.sass'

export const ReferencesSection: FunctionComponent = () => {
	return (
		<div className={style.root}>
			<TitleHighlighted
				title="You are the future"
				backgroundTitle={
					<span className={style.breakVisibleOnMobile}>
						Referen
						<br />
						ces
					</span>
				}
			/>
			<div className={style.cardGroup}>
				<Swiper
					content={
						<>
							{references.map((reference) => (
								<ReferenceCard
									key={reference.title}
									imagePath={reference.imagePath}
									logoPath={reference.logoPath}
									title={reference.title}
									content={reference.content}
									contentElement={reference.contentElement}
								/>
							))}
						</>
					}
				/>
			</div>
		</div>
	)
}
