export const logosHero = [
	{ title: 'Binance', imagePath: '/images/binanceLogo.png', url: 'https://www.binance.com' },
	{ title: 'ReFork', imagePath: '/images/reforkLogoRow.png', url: 'https://www.refork.com' },
	{ title: 'BlockCzech', imagePath: '/images/blockCzech.png', url: 'https://www.blockczech.io' },
]

export const logosContent = [
	{ title: 'NFTLabs', imagePath: '/images/nftLabs.png', url: 'https://www.nftlabs.cz' },
	{ title: 'BlockCzech', imagePath: '/images/blockCzech.png', url: 'https://www.blockczech.io' },
	{ title: 'ReFork', imagePath: '/images/reforkLogoRow.png', url: 'https://www.refork.com' },
	{ title: 'Binance', imagePath: '/images/binanceLogo.png', url: 'https://www.binance.com' },
	{
		title: 'Live Bid',
		imagePath: '/images/liveBid.png',
		url: 'https://www.livebid.cz/auctions?locale=en',
	},
	{ title: 'Blinders', imagePath: '/images/blinders.svg' },
]
