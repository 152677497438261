import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import style from './ServicesMobile.module.sass'

export type ListItemProps = {
	service: { title: string; content: JSX.Element | string }
	index: number
	firstElementHovered?: boolean
}

export const ListItem: FunctionComponent<ListItemProps> = ({
	service,
	index,
	firstElementHovered,
}) => {
	return (
		<li
			key={service.title}
			id="service"
			className={clsx(style.wrapper, firstElementHovered && index === 0 && style.hovered)}>
			<h6 className={style.title}>{service.title}</h6>
			<p className={style.content}>{service.content}</p>
		</li>
	)
}
