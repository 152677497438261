import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import style from './TitleHighlighted.module.sass'

export type TitleHighlightedProps = {
	title: string | JSX.Element
	backgroundTitle: string | JSX.Element
	isAlignedToLeft?: boolean
	isTwoLines?: boolean
}

export const TitleHighlighted: FunctionComponent<TitleHighlightedProps> = ({
	title,
	backgroundTitle,
	isAlignedToLeft,
	isTwoLines,
}) => {
	return (
		<div className={clsx(isAlignedToLeft && style.rootLeft, style.root)}>
			<div className={style.titleMainWrapper}>
				<div
					className={clsx(
						style.titleBackgroundWrapper,
						isTwoLines && style.titleBackgroundWrapperTwoLines,
						isAlignedToLeft && style.titleBackgroundWrapperLeft
					)}>
					<h4 className={style.titleMain}>{backgroundTitle}</h4>
				</div>
				<h5 className={style.titleBackground}>{title}</h5>
			</div>
		</div>
	)
}
