import type { FunctionComponent } from 'react'
import { ListItem } from './ListItem'
import style from './ServicesMobile.module.sass'
import { ToggleButton } from './ToggleButton'

export type ServicesMobileProps = {
	services: { title: string; content: JSX.Element | string }[]
}

export const ServicesMobile: FunctionComponent<ServicesMobileProps> = ({ services }) => {
	return (
		<div className={style.root} id="services">
			<div className={style.listMobile}>
				{services.map((service, index) => (
					<ToggleButton title={service.title} key={service.title} content={service.content}>
						<ListItem service={service} index={index} />
					</ToggleButton>
				))}
			</div>
		</div>
	)
}
