export const references = [
	{
		title: 'Binance',
		imagePath: '/images/binanceBackgroundImage.jpg',
		logoPath: '/images/binanceLogo.png',
		logoUrl: 'https://www.binance.com',
		contentElement: (
			<>
				Binance is the world&apos;s leading blockchain ecosystem and infrastructure provider for
				cryptocurrencies with a suite of financial products, most notably the world&apos;s largest
				digital asset exchange by volume. Trusted by millions of people around the world.
				<br />
				<br />
				We provide a comprehensive PR service in Central Europe that includes media relations,
				collaboration with influencers, scouting and negotiating potential collaborations and
				creating strategic analyses of the company&apos;s development.
			</>
		),
		content:
			"Binance is the world's leading blockchain ecosystem and infrastructure provider for cryptocurrencies with a suite of financial products, most notably the world's largest digital asset exchange by volume. Trusted by millions of people around the world. We provide a comprehensive PR service in Central Europe that includes media relations, collaboration with influencers, scouting and negotiating potential collaborations and creating strategic analyses of the company's development.",
	},
	{
		title: 'ReFork',
		imagePath: '/images/reforkBackgroundImage.jpg',
		logoPath: '/images/refork.png',
		logoUrl: 'https://www.refork.com',
		contentElement: (
			<>
				Refork is a global leader in sustainable materials technology. Their retail products are
				made of patented alternative to single-use plastics = natural polymer PHB(V). Combined with
				wood flour and minerals, give a wide variety of product possibilities. Now investing heavily
				in their own cryptocurrency = EFK.
				<br />
				<br /> We help Refork with their communications strategy including operations, PR and other
				activities connected to their growth and new exchange listing.
			</>
		),
		content:
			'Refork is a global leader in sustainable materials technology. Their retail products are made of patented alternative to single-use plastics = natural polymer PHB(V). Combined with wood flour and minerals, give a wide variety of product possibilities. Now investing heavily in their own cryptocurrency = EFK. We help Refork with their communications strategy including operations, PR and other activities connected to their growth and new exchange listing.',
	},
	{
		title: 'Blinders',
		imagePath: '/images/blindersBackgroundImage.png',
		logoPath: '/images/blinders.svg',
		contentElement: (
			<>
				Blinders is a tech company rendering the visualisation of family houses in the study phase,
				where the client verifies the space and room layout based on the documents provided by the
				architects.
				<br />
				<br />
				We were involved in product development, secured the product launch, put together a
				comprehensive communications strategy and developed a PR strategy to enter the media space
				and began to build a community of collaborating architects, designers and home type
				providers.
			</>
		),
		content:
			'Blinders is a tech company rendering the visualisation of family houses in the study phase, where the client verifies the space and room layout based on the documents provided by the architects. We were involved in product development, secured the product launch, put together a comprehensive communications strategy and developed a PR strategy to enter the media space and began to build a community of collaborating architects, designers and home typeproviders.',
	},
	/* {
		title: 'NFTLabs',
		imagePath: '/images/nftLabsBackgroundImage.jpg',
		logoPath: '/images/nftLabs.png',
		content: (
			<>
				Blinders is a tech company rendering the visualisation of family houses in the study phase,
				where the client verifies the space and room layout based on the documents provided by the
				architects.
				<br />
				<br />
				We were involved in product development, secured the product launch, put together a
				comprehensive communications strategy and developed a PR strategy to enter the media space
				and began to build a community of collaborating architects, designers and home type
				providers.
			</>
		),
	}, */
]
