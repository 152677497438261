/* eslint-disable @typescript-eslint/no-non-null-assertion */
import clsx from 'clsx'
import type * as react from 'react'
import { Parallax } from 'react-scroll-parallax'
import { Card } from './Card'
import style from './CardGroup.module.sass'
import { Swiper } from './Swiper'

export type CardGroupProps = {
	cards: { title: string; imagePath: string }[]
}

export const CardGroup: react.FunctionComponent<CardGroupProps> = ({ cards }) => {
	return (
		<div className={style.root}>
			<div className={clsx(style.row, style.rowToRight)}>
				<Parallax x={[0, -15]} tagOuter="figure" className={style.parallax}>
					<Swiper
						content={
							<>
								{cards
									.map((card) => (
										<Card key={card.title} title={card.title} imagePath={card.imagePath} />
									))
									.slice(0, 3)}
							</>
						}
					/>
				</Parallax>
			</div>
			<div className={clsx(style.row, style.rowToLeft)}>
				<Parallax x={[0, 20]} tagOuter="figure" className={style.parallax}>
					<Swiper
						content={
							<>
								{cards
									.map((card) => (
										<Card key={card.title} title={card.title} imagePath={card.imagePath} />
									))
									.slice(3, 6)}
							</>
						}
					/>
				</Parallax>
			</div>
			<div className={clsx(style.row, style.rowToRight)}>
				<Parallax x={[0, -15]} tagOuter="figure" className={style.parallax}>
					<Swiper
						content={
							<>
								{cards
									.map((card) => (
										<Card key={card.title} title={card.title} imagePath={card.imagePath} />
									))
									.slice(6, 9)}
							</>
						}
					/>
				</Parallax>
			</div>
		</div>
	)
}
